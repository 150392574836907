









































































































































































































































































































































































































































































































































































import FastGameMenu from '@/components/menus/FastGameMenu.vue'
import { Component, Vue } from 'vue-property-decorator'
import { namespace } from 'vuex-class'
import Common from "@/mixins/Common.vue"
import axios from 'axios'

const SettingsGetter = namespace('settings').Getter
const UIMutation = namespace('ui').Mutation
const AccountGetter = namespace('account').Getter

const AppProps = Vue.extend({
  data: () => {
    return {
      isMobile: /iPhone|iPad|iPod|Android|BlackBerry|Opera Mini|IEMobile/i.test(navigator.userAgent),
      valid_credit_sizes: [],
      credit_btc_value: 0,
      reels: [],
      initPositionsMatrix: [],
      dialog_system: null,
      freespins: {
        left: 0,
        num_lines: 1
      },
      isAccountLoaded: false
    }
  },
  metaInfo: {
    meta: [{
      vmid: 'description',
      name: 'description',
      content: `Play Classic 5 Reel Progressive Slot Online with Bitcoin, Win Big Jackpots & Cashout Instantly at the best Bitcoin Games Casino`
    },
    {
      vmid: 'title',
      name: 'title',
      content: `Slots`
    },
    {
      vmid: 'keywords',
      name: 'keywords',
      content: `bitcoin casino, btc casino, bch casino, crypto casino, bitcoin gambling, bitcoin casino no deposit, crypto casino no deposit, best bitcoin casino, bitcoin games`
    }],
  },
  mixins: [Common],
  props: {},
  created () {
    // @ts-ignore
    this.setFooterDisabled(true)
    this.setBalanceDisabled(false)
    document.body.style.backgroundColor = '#212b31'
  },
  methods: {
    getMatrixPosition(col) {
      let rnd = Math.floor(Math.random() * 17)
      return this.reels[col][rnd]
    },
    async getRuleSet() {
      try {
        let res = await axios.get('/api/slots/ruleset')
        if(res.data.result) {
          this.reels = res.data.result.reels
          this.valid_credit_sizes = res.data.result.valid_credit_sizes
          this.getPlatformCurrency == "BTC" ? this.credit_btc_value = 100 : this.credit_btc_value = 50000
          return res.data.result
        } else {
          throw new Error('Invalid response')
        }
      }
      catch(e) {
        this.$router.push('maintenance')
        console.log("Error trying to get the ruleset")
        return e
      }
    },
    async reseed() {
      try {
        let res = await axios.post('/api/slots/seed')
        if(res.data.server_seed_hash) {
          return res.data.server_seed_hash
        } else {
          throw new Error('invalid response')
        }
      }
      catch(e) {
        console.log("something went wrong when trying to reseed")
        return e
      }
    },
    async checkFreeSpins() {
      let that = this;
      try {
        let res = await axios.get('/api/slots/freespins')
        if(res.data.free_spins > 0) {
          this.freespins = {
            'left': res.data.free_spins,
            'num_lines' : res.data.num_lines,
          }
          this.credit_btc_value = res.data.credit_size
        } else {
          throw new Error()
        }
      }
      catch(e) {
        window.setTimeout(function () {
          if (that.$route.name === 'slots') {
            that.checkFreeSpins();
          }
        }, 5000);
        return e
      }
    },
    async initGame() {
      this.isAccountLoaded = true;
      this.initializeGameScale()
      //@ts-ignore
      await this.$loadScript('/js/legacy/slots.js')
      try {
        let ruleset = await this.getRuleSet()
        this.reels = ruleset.reels
        let starting_server_seed_hash = await this.reseed()
        await this.checkFreeSpins();
        let creditModal = "#btc_item_" + this.classSuffixFromToken(this.credit_btc_value)
        $(creditModal).addClass("selected");
        // @ts-ignore
        // eslint-disable-next-line no-undef
        init_slots("123", "123", "123", starting_server_seed_hash, [], [], [], ruleset, this.credit_btc_value, 10, this.freespins);

        if(this.isMobile){
          $("#ultra_container").addClass("heightlock");
        }
        // Legacy init - This is calling functions in /public/js/legacy/common.js
        // Should refactor the application to do it non-legacy way
        // @ts-ignore
        // eslint-disable-next-line no-undef
        setupHelp(); setupFullScreen(); setupSound();
      } catch (e) {
        console.log("error", e)
      }
    }
  },
  watch: {

  },
  async mounted () {
    // note that its possible the usertoken is not yet available,
    // in this case the watcher will init slots game when the token
    // is available
   this.initGame();
  }
})
@Component({
  components: {
    FastGameMenu
  }
})
export default class Slots extends AppProps {
  @UIMutation('setFooterDisabled') setFooterDisabled
  @UIMutation('setBalanceDisabled') setBalanceDisabled
  @SettingsGetter getPlatformCurrency
  @AccountGetter userToken
}
